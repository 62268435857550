import React, { Component } from 'react';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/eclipse.css'
import 'codemirror/addon/scroll/simplescrollbars.css'
import 'codemirror/addon/scroll/simplescrollbars.js'
import 'codemirror/mode/xml/xml'
import './react-code-modal.css';
import format from 'xml-formatter';

class ReadCodeModal extends Component {

    constructor(props) {
        super(props);

        this.options = {
            lineNumbers: true,
            mode: 'xml',
            theme: 'eclipse',
            autofocus: true,
            scrollbarStyle: 'simple'
        };
    }

    onCloseMethod = () => {
        this.props.onClose();
    };

    render() {
        let sh = window.innerHeight;

        return (
            <div className="modal fade" id={this.props.type + 'Modal'} tabIndex="-1" aria-labelledby="proceedCancelModalLabel" aria-hidden="true" style={{ display: this.props.visible ? 'block' : 'none', opacity: 1, top: 'calc(50% - 363px)', padding: '20px', width: '100%' }}>
                <div className={'modal-dialog modal-lg modal-' + this.props.type} role="document" style={{ maxWidth: '90%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{ this.props.title }</h4>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this.onCloseMethod}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <CCard>
                                <CCardBody>
                                    <CodeMirror
                                        value={ this.props.content ? format(this.props.content) : '' }
                                        options={ this.options }
                                    />
                                </CCardBody>
                            </CCard>
                        </div>
                        <div className="modal-footer">
                            <button className={'btn btn-' + this.props.type} type="button" onClick={this.onCloseMethod}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReadCodeModal;
