import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const fileSizeMetricTypes = {
    '': 1,
    'kb': 1024,
    'mb': 1024 * 1024,
    'gb': 1024 * 1024 * 1024,
};

const FileUploadDropZone = ( props ) => {
    const onDrop = useCallback(acceptedFiles => {
        props.onDrop( acceptedFiles );
    }, []);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: props.accepts.type,
        onDrop: onDrop,
        maxFiles: props.maxFiles ? props.maxFiles : 0,
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
    ]);

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={ file.path }>
            { file.path } - { ( file.size / fileSizeMetricTypes[props.fileSizeMetric ? props.fileSizeMetric : ''] ).toFixed(2) } { props.fileSizeMetric ? props.fileSizeMetric : '' }
        </li>
    ));

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>{ props.labels.onDrop ? props.labels.onDrop : 'Drop files here ...' }</p> :
                    <p>
                        { props.labels.instructions ? props.labels.instructions : 'Drag n drop some files here, or click to select files.' }<br />
                        { props.labels.accepts ? props.labels.accepts : 'Accepts' }: { props.accepts.label }
                    </p>
            }
            {
                acceptedFileItems.length ?
                    <h4>{ props.labels.acceptedFiles ? props.labels.acceptedFiles : 'Accepted files' }</h4> :
                    <h4>&nbsp;</h4>
            }
            <ul>{ acceptedFileItems }</ul>
        </div>
    );
};

export default FileUploadDropZone;
