import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import routes from './routes';

// import Security from './helpers/security';

// const UnauthorisedAccess = () => {
//   let user = Security.getCurrentUser();

//   if (user && user.cookie) {
//     return "";
//   } else {
//     return (
//       <Redirect from="/dashboard/" to="/dashboard/dash-login" />
//     );
//   }
// }

class App extends Component {
    render() {
        function useQuery() {
            return new URLSearchParams(window.location.search);
        }

        let query = useQuery();
        var jwt_token = query.get('jwt_token');

        if (jwt_token) {
            localStorage.setItem('admin_portal_jwt_token', jwt_token);
            window.location.href = '/';
        }

        // const routes = [
        //     { path: '/', exact: true, name: 'Dashboard', component: CommissionableHotel },
        // ];

        return (
            <SnackbarProvider
                maxSnack={ 3 }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={ 3000 }>
                <Router>
                    <Switch>
                        {routes.map((item, index) => {
                            if (item.path) {
                                const Component = item.component;
                                const ComponentWrapper = item.component ? <Component/> : <div/>;
                                return (
                                    <Route path={item.path} render={() => ComponentWrapper} exact={item.exact} key={index}/>
                                );
                            } else {
                                return '';
                            }
                        })}

                        {/* <Route path="/dashboard/dash-login" component={Login} /> */}
                        {/* <Route path="/dashboard/dash-logout" component={Logout} />
                        <Route path="/dashboard/editor/country/:areaid" render={(props) => <Editor scope={ 0 } {...props} />} />
                        <Route path="/dashboard/editor/municipality/:areaid" render={(props) => <Editor scope={ 1 } {...props} />} />
                        <Route path="/dashboard/country/:areaid" render={(props) => <Area scope={ 0 } {...props} />} />
                        <Route path="/dashboard/municipality/:areaid" render={(props) => <Area scope={ 1 } {...props} />} />
                        <Route path="/dashboard/profile" component={Profile} />
                        <Route path="/dashboard/content/:content" component={Content} />
                        <Route path="/dashboard/municipalities" render={(props) => <Dashboard scope={ 1 } {...props} />} />
                        <Route path="/dashboard" component={Dashboard} /> */}
                        {/* <UnauthorisedAccess /> */}
                    </Switch>
                </Router>
            </SnackbarProvider>
        );
    }
}

export default App;
