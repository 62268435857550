import React, { Component } from 'react';

class DataEditorModal extends Component {

    constructor(props) {
        super(props);
    }

    onCancelMethod = () => {
        this.props.onCancel();
    };

    onSaveMethod = () => {
        this.props.onSave();
    };

    render() {
        return (
            <div className="modal fade" id={this.props.type + 'Modal'} tabIndex="-1" aria-labelledby="proceedCancelModalLabel" aria-hidden="true" style={{ display: this.props.visible ? 'block' : 'none', opacity: 1, top: '10%', padding: '20px' }}>
                <div className={'modal-dialog modal-' + this.props.type} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{ this.props.title }</h4>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this.onCancelMethod}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            { this.props.html }
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={this.onCancelMethod}>Cancel</button>
                            <button className={'btn btn-' + this.props.type} type="button" onClick={this.onSaveMethod}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DataEditorModal;
