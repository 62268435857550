import React, { Component } from 'react';

class CommissionableHotelsForm extends Component {
    constructor(props) {
        super(props);

        this.onDataUpdate = this.onDataUpdate.bind(this);
    }

    onDataUpdate() {
        var hotel = {
            hotelCode: document.getElementById('hotelcode').value,
            sabreId: document.getElementById('sabreid').value,
            isCommissionable: document.getElementById('iscommissionable').checked ? true : false,
        }

        this.props.onDataUpdate(hotel, hotel.hotelCode && hotel.sabreId);
    }

    render() {
        return (
            <div className="col-sm-12">
                <div className="form-group">
                    <label htmlFor="hotelcode">Hotel Code</label>
                    <input className="form-control" id="hotelcode" type="text" placeholder="Enter Hotel Code" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="sabreid">Sabre ID</label>
                    <input className="form-control" id="sabreid" type="text" placeholder="Enter Sabre ID" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group row">
                    <label className="col-md-5 col-form-label">Is Commissionable</label>
                    <div className="col-md-7 col-form-label">
                        <div className="form-check form-check-inline mr-1">
                            <input className="form-check-input" id="iscommissionable" type="checkbox" value="true" onInput={ this.onDataUpdate } />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommissionableHotelsForm;
