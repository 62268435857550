import React, { Component } from 'react';

class PMSHotelsForm extends Component {
    constructor(props) {
        super(props);

        this.onDataUpdate = this.onDataUpdate.bind(this);
    }

    onDataUpdate() {
        var hotel = {
            hotelCode: document.getElementById('hotelCode').value,
            username: document.getElementById('username').value,
            status: document.getElementById('status').value,
            connectionType: document.getElementById('connectionType').value,
            sabreHotelCode: document.getElementById('sabreHotelCode').value,
        }

        this.props.onDataUpdate(hotel, hotel.hotelCode && hotel.username && hotel.status && hotel.connectionType && hotel.sabreHotelCode);
    }

    render() {
        return (
            <div className="col-sm-12">
                <div className="form-group">
                    <label htmlFor="hotelCode">Hotel Code</label>
                    <input className="form-control" id="hotelCode" type="text" placeholder="Enter Hotel Code" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input className="form-control" id="username" type="text" placeholder="Enter username" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <input className="form-control" id="status" type="text" placeholder="Enter status" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="connectionType">Connection Type</label>
                    <input className="form-control" id="connectionType" type="text" placeholder="Enter connection type" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="sabreHotelCode">Sabre ID</label>
                    <input className="form-control" id="sabreHotelCode" type="text" placeholder="Enter Sabre ID" onInput={ this.onDataUpdate } />
                </div>
            </div>
        );
    }
}

export default PMSHotelsForm;
