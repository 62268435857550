import axios from 'axios';
import Config from '../Config';

class Services {

    static checkForUnauthorisedAccess(error) {
        if (error && error.response && Math.floor(error.response.status / 100) === 4) {
            window.location.replace(Config.expiredJWTRedirectURL);
        }
    }

    static wrapError(error) {
        if (error && error.response && error.response.status) {
            return error;
        } else {
            return {
                response: {
                    status: 500,
                    message: error && error.message ? error.message : 'An error has occured, please contact itsupport@hotelrez.com!',
                },
            };
        }
    }

    static getDefaultHeaders() {
        return {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Config.getJWTToken(),
        };
    }

    static getApiData(apiUrl, callback, errorCallback) {
        axios.get(apiUrl,{
            headers: Services.getDefaultHeaders(),
        })
            .then(function(response) {
                if (response.data.status === 'ok' && response.data.code === 200) {
                    callback(response.data.data);
                } else {
                    errorCallback(response.data.message);
                }
            })
            .catch(function(error) {
                Services.checkForUnauthorisedAccess(error);
                errorCallback(error);
            });
    }

    static getWithPostApiData(apiUrl, criteria, callback, errorCallback) {
        axios.post(apiUrl, criteria,{
            headers: Services.getDefaultHeaders(),
        })
            .then(function(response) {
                if (response.data.status === 'ok' && response.data.code === 200) {
                    callback(response.data.data);
                } else {
                    errorCallback(response.data.message);
                }
            })
            .catch(function(error) {
                Services.checkForUnauthorisedAccess(error);
                errorCallback(Services.wrapError(error).response.message);
            });
    }

    static addApiData(apiUrl, item, callback, errorCallback) {
        axios.post(apiUrl, item,{
            headers: Services.getDefaultHeaders(),
        })
            .then(function(response) {
                if (response.data.status === 'ok' && response.data.code === 200) {
                    callback(response.data.data);
                } else {
                    errorCallback(response.data.message);
                }
            })
            .catch(function(error) {
                Services.checkForUnauthorisedAccess(error);
                errorCallback(Services.wrapError(error).response.message);
            });
    }

    static addWithPutApiData(apiUrl, item, callback, errorCallback) {
        axios.put(apiUrl, item,{
            headers: Services.getDefaultHeaders(),
        })
            .then(function(response) {
                if (response.data.status === 'ok' && response.data.code === 200) {
                    callback(response.data.data);
                } else {
                    errorCallback(response.data.message);
                }
            })
            .catch(function(error) {
                Services.checkForUnauthorisedAccess(error);
                errorCallback(Services.wrapError(error).response.message);
            });
    }

    static updateApiData(apiUrl, item, callback, errorCallback) {
        let id = item.id ? item.id : item.ID;
        axios.put(apiUrl(id), item, {
            headers: Services.getDefaultHeaders(),
        })
            .then(function(response) {
                if (response.data.status === 'ok' && response.data.code === 200) {
                    callback(response.data.code);
                } else {
                    errorCallback(response.data.message);
                }
            })
            .catch(function(error) {
                Services.checkForUnauthorisedAccess(error);
                errorCallback(Services.wrapError(error).response.message);
            });
    }

    static deleteApiData(apiUrl, item_id, callback, errorCallback) {
        axios.delete(apiUrl(item_id), {
            headers: Services.getDefaultHeaders(),
        })
            .then(function(response) {
                if (response.data.status === 'ok' && response.data.code === 200) {
                    callback(response.data.data);
                } else {
                    errorCallback(response.data.message);
                }
            })
            .catch(function(error) {
                Services.checkForUnauthorisedAccess(error);
                errorCallback(Services.wrapError(error).response.message);
            });
    }

    static uploadFilesApiData(apiUrl, files, callback, errorCallback) {
        const filesObject = new FormData()
        filesObject.append('file1', files[0]);
        filesObject.append('file2', files[1]);

        axios.post(apiUrl(), filesObject, {
            headers: Services.getDefaultHeaders(),
        })
            .then(function(response) {
                if (response.data.status === 'ok' && response.data.code === 200) {
                    callback(response.data);
                } else {
                    errorCallback(response.data.message);
                }
            })
            .catch(function(error) {
                Services.checkForUnauthorisedAccess(error);
                errorCallback(Services.wrapError(error).response.message);
            });
    }
}

export default Services;
