import React, { Component } from 'react';
import ManageSearchDataTable from '../table-templates/manage-search-data-table';
import Services from '../helpers/services';
import CommissionableHotelsForm from '../components/forms/commissionable-hotels-form';
import Config from '../Config';
import Loader from "../components/loader";
import LeftSidebar from "../components/left-sidebar";
import RightSidebar from "../components/right-sidebar";
import Header from "../components/header";
import Footer from "../components/footer";

class CommissionableHotel extends Component {

    constructor(props) {
        super(props);

        var controller = this;

        controller.columns = [
            {
                headerName: 'ID',
                field: 'id',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
                maxWidth: 90,
            },
            {
                headerName: 'Hotel Code',
                field: 'hotelCode',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                maxWidth: 170,
            },
            {
                headerName: 'Hotel Name',
                field: 'hotelName',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                maxWidth: 300,
            },
            {
                headerName: 'Sabre ID',
                field: 'sabreId',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
                maxWidth: 170,
            },
            {
                headerName: 'Is Commissionable',
                field: 'isCommissionable',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['true', 'false'],
                },
                maxWidth: 200,
            },
            {
                headerName: 'Manage',
                field: 'id',
                cellRenderer: 'actionCellButtons',
                cellRendererParams: {
                    clicked: function(data) {
                        controller.manageSearchDataTableRef.showProceedModal(data);
                    },
                    icon: 'cil-trash',
                    sendFullData: true,
                },
                editable: false,
                sortable: false,
                filter: false,
                resizable: true,
                maxWidth: 150,
            },
        ];

        controller.titleSingle = 'Onyx Registered Hotel';
        controller.titlePlural = 'Onyx Registered Hotels';
    }

    getCommissionHotels(callback, errorCallback) {
        Services.getApiData(Config.commissionHotelApiPath(), callback, errorCallback);
    }

    addCommissionHotel(hotel, callback, errorCallback) {
        Services.addApiData(Config.commissionHotelApiPath(), hotel, callback, errorCallback);
    }

    updateCommissionHotel(hotel, callback, errorCallback) {
        Services.updateApiData(Config.commissionHotelApiPath, hotel, callback, errorCallback);
    }

    deleteCommissionHotel(hotelId, callback, errorCallback) {
        Services.deleteApiData(Config.commissionHotelApiPath, hotelId, callback, errorCallback);
    }

    render() {
        return (
            <div>
                <LeftSidebar />
                <RightSidebar />
                <div className="c-wrapper">
                    <Header />
                    <div className="c-body">
                        <ManageSearchDataTable
                            columns={ this.columns }
                            titleSingle={ this.titleSingle }
                            titlePlural={ this.titlePlural }
                            getRows={ this.getCommissionHotels }
                            addRow={ this.addCommissionHotel }
                            updateRow={ this.updateCommissionHotel }
                            deleteRow={ this.deleteCommissionHotel }
                            itemUniqueColumn={ 'id' }
                            itemHumanFriendlyColumn={ 'hotelCode' }
                            addRowForm={ CommissionableHotelsForm }
                            pagination={ true }
                            onRef={ (ref) => ( this.manageSearchDataTableRef = ref ) }
                        />
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default CommissionableHotel;
