import React, { Component } from 'react';
import routes from '../routes';

class LeftSidebar extends Component {

    render() {
        return (
            <div className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar">
                <div className="c-sidebar-brand d-md-down-none">
                    <img src="/assets/img/hotelrez_logo.png" alt="" />
                </div>

                <ul className="c-sidebar-nav">
                    {routes.map((item, index) => {
                        if (item.path && item.level === 0 && item.has_children === false) {
                            return (
                                <li className="c-sidebar-nav-item" key={index}>
                                    <a className="c-sidebar-nav-link" href={item.path}>
                                        <svg className="c-sidebar-nav-icon">
                                            <use xlinkHref={'/vendors/@coreui/icons/svg/free.svg#' + item.icon}></use>
                                        </svg>
                                        { item.name }
                                    </a>
                                </li>
                            );
                        } else if (item.path && item.level === 0 && item.has_children === true) {
                            return (
                                <li className="c-sidebar-nav-dropdown c-show" key={ index }>
                                    <a className="c-sidebar-nav-dropdown-toggle" href="#">
                                        <svg className="c-sidebar-nav-icon">
                                            <use xlinkHref={'/vendors/@coreui/icons/svg/free.svg#' + item.icon}></use>
                                        </svg>
                                        { item.name }
                                    </a>
                                    <ul className="c-sidebar-nav-dropdown-items">
                                        {routes.map((child_item, child_index) => {
                                            if (child_item.path && child_item.level === 1 && child_item.parent === item.path) {
                                                return (
                                                    <li className="c-sidebar-nav-item" key={child_index}>
                                                        <a className="c-sidebar-nav-link" href={child_item.path}>
                                                            <svg className="c-sidebar-nav-icon">
                                                                <use xlinkHref={'/vendors/@coreui/icons/svg/free.svg#' + child_item.icon}></use>
                                                            </svg>
                                                            { child_item.name }
                                                        </a>
                                                    </li>
                                                );
                                            } else {
                                                return '';
                                            }
                                        })}
                                    </ul>
                                </li>
                            );
                        } else if (!item.path) {
                            return (
                                <li className="c-sidebar-nav-title" key={index}>{ item.name }</li>
                            );
                        } else {
                            return '';
                        }
                    })}

                    {/*<li className="c-sidebar-nav-title">REZSwitch</li>*/}
                    {/*<li className="c-sidebar-nav-dropdown">*/}
                    {/*    <a className="c-sidebar-nav-link" href="/rezswitch/commissionable-hotels">*/}
                    {/*        <svg className="c-sidebar-nav-icon">*/}
                    {/*            <use xlinkHref="/vendors/@coreui/icons/svg/free.svg#cil-house"></use>*/}
                    {/*        </svg>*/}
                    {/*        Onyx Registered Hotels*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/*<li className="c-sidebar-nav-dropdown">*/}
                    {/*    <a className="c-sidebar-nav-link" href="/rezswitch/pms-messages">*/}
                    {/*        <svg className="c-sidebar-nav-icon">*/}
                    {/*            <use xlinkHref="/vendors/@coreui/icons/svg/free.svg#cil-envelope-open"></use>*/}
                    {/*        </svg>*/}
                    {/*        PMS Messages*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                </ul>
                <button className="c-sidebar-minimizer c-class-toggler" type="button" data-target="_parent" data-class="c-sidebar-unfoldable"></button>
            </div>
        );
    }
}

export default LeftSidebar;
