import React, { Component } from 'react';
import ManageSearchDataTable from '../table-templates/manage-search-data-table';
import Services from '../helpers/services';
import PMSHotelsForm from '../components/forms/pms-hotels-form';
import Config from '../Config';
import LeftSidebar from "../components/left-sidebar";
import RightSidebar from "../components/right-sidebar";
import Header from "../components/header";
import Footer from "../components/footer";

class PMSHotel extends Component {

    constructor(props) {
        super(props);

        var controller = this;

        controller.columns = [
            {
                headerName: 'ID',
                field: 'id',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
                maxWidth: 90,
            },
            {
                headerName: 'Hotel Code',
                field: 'hotelCode',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                maxWidth: 170,
            },
            {
                headerName: 'Hotel Name',
                field: 'hotelName',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                maxWidth: 300,
            },
            {
                headerName: 'User Name',
                field: 'username',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                maxWidth: 300,
            },
            {
                headerName: 'Status',
                field: 'status',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Connection Type',
                field: 'connectionType',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Sabre Hotel Code',
                field: 'sabreHotelCode',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
                maxWidth: 170,
            },
            {
                headerName: 'Manage',
                field: 'id',
                cellRenderer: 'actionCellButtons',
                cellRendererParams: {
                    clicked: function(data) {
                        controller.manageSearchDataTableRef.showProceedModal(data);
                    },
                    icon: 'cil-trash',
                    sendFullData: true,
                },
                editable: false,
                sortable: false,
                filter: false,
                resizable: true,
                maxWidth: 150,
            },
        ];

        controller.titleSingle = 'PMS hotel';
        controller.titlePlural = 'PMS hotels';
    }

    getPMSHotels(callback, errorCallback) {
        Services.getApiData(Config.pmsHotelApiPath(), callback, errorCallback);
    }

    addPMSHotel(hotel, callback, errorCallback) {
        Services.addApiData(Config.pmsHotelApiPath(), hotel, callback, errorCallback);
    }

    updatePMSHotel(hotel, callback, errorCallback) {
        Services.updateApiData(Config.pmsHotelApiPath, hotel, callback, errorCallback);
    }

    deletePMSHotel(hotelId, callback, errorCallback) {
        Services.deleteApiData(Config.pmsHotelApiPath, hotelId, callback, errorCallback);
    }

    render() {
        return (
            <div>
                <LeftSidebar />
                <RightSidebar />
                <div className="c-wrapper">
                    <Header />
                    <div className="c-body">
                        <ManageSearchDataTable
                            columns={ this.columns }
                            titleSingle={ this.titleSingle }
                            titlePlural={ this.titlePlural }
                            getRows={ this.getPMSHotels }
                            addRow={ this.addPMSHotel }
                            updateRow={ this.updatePMSHotel }
                            deleteRow={ this.deletePMSHotel }
                            itemUniqueColumn={ 'id' }
                            itemHumanFriendlyColumn={ 'hotelCode' }
                            addRowForm={ PMSHotelsForm }
                            pagination={ true }
                            onRef={ (ref) => ( this.manageSearchDataTableRef = ref ) }
                        />
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default PMSHotel;
