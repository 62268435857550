import React from 'react';
import CommissionableHotel from './rezswitch/commissionable-hotel';
import PMSMessage from './rezswitch/pms-message';
import PMSHotel from './rezswitch/pms-hotel';
import PMSPerformance from './rezswitch/pms-performance';
import PMSHotelProductUpload from './rezswitch/pms-hotel-product-upload';
import PMSHotelProductManage from './rezswitch/pms-hotel-product-manage';
import PMSReservations from './rezswitch/pms-reservations';

const routes = [
    { path: '/', exact: true, name: 'Dashboard', component: CommissionableHotel, icon: 'cil-speedometer', level: 0, has_children: false },

    { name: 'Financial', level: 0, has_children: false },
    { path: '/onyx', exact: true, name: 'Onyx', component: CommissionableHotel, icon: 'cil-layers', level: 0, has_children: true },
    { path: '/onyx/commissionable-hotels', exact: true, name: 'Onyx Registered Hotels', component: CommissionableHotel, icon: 'cil-money', level: 1, has_children: false, parent: '/onyx' },

    { name: 'REZswitch', level: 0, has_children: false },
    { path: '/rezswitch', exact: true, name: 'REZswitch', component: PMSMessage, icon: 'cil-layers', level: 0, has_children: true },
    { path: '/rezswitch/pms-messages', exact: true, name: 'PMSMessages', component: PMSMessage, icon: 'cil-envelope-open', level: 1, has_children: false, parent: '/rezswitch' },
    { path: '/rezswitch/pms-reservations', exact: true, name: 'PMSReservations', component: PMSReservations, icon: 'cil-door', level: 1, has_children: false, parent: '/rezswitch' },
    { path: '/rezswitch/pms-hotels', exact: true, name: 'PMSHotels', component: PMSHotel, icon: 'cil-house', level: 1, has_children: false, parent: '/rezswitch' },
    { path: '/rezswitch/pms-performance', exact: true, name: 'PMSPerformance', component: PMSPerformance, icon: 'cil-speedometer', level: 1, has_children: false, parent: '/rezswitch' },
    { path: '/rezswitch/pms-hotel-products', exact: true, name: 'PMSHotelProducts', component: PMSHotelProductManage, icon: 'cil-layers', level: 0, has_children: true },
    { path: '/rezswitch/pms-hotel-products/manage', exact: true, name: 'PMSHotelProductsManage', component: PMSHotelProductManage, icon: 'cil-room', level: 1, has_children: false, parent: '/rezswitch/pms-hotel-products' },
    { path: '/rezswitch/pms-hotel-products/upload', exact: true, name: 'PMSHotelProductsUpload', component: PMSHotelProductUpload, icon: 'cil-room', level: 1, has_children: false, parent: '/rezswitch/pms-hotel-products' },

    // { path: '/users/:id', exact: true, name: 'User Details', component: User },
]

export default routes;
