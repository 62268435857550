import React, { Component } from 'react';

class PMSHotelProductForm extends Component {
    constructor(props) {
        super(props);

        this.onDataUpdate = this.onDataUpdate.bind(this);
    }

    onDataUpdate() {
        var hotel = {
            HotelID: document.getElementById('HotelID').value,
            RoomCode: document.getElementById('RoomCode').value,
            RoomName: document.getElementById('RoomName').value,
            RateCode: document.getElementById('RateCode').value,
            RateName: document.getElementById('RateName').value,
            Derived: document.getElementById('Derived').value,
            RoomMaxOcupancy: document.getElementById('RoomMaxOcupancy').value,
            IsCorporateRate: document.getElementById('IsCorporateRate').checked ? 1 : 0,
        }

        this.props.onDataUpdate(
            hotel,
            hotel.HotelID &&
            hotel.RoomCode &&
            hotel.RoomName &&
            hotel.RateCode &&
            hotel.RateName &&
            hotel.RoomMaxOcupancy);
    }

    render() {
        return (
            <div className="col-sm-12">
                <div className="form-group">
                    <label htmlFor="HotelID">Hotel ID</label>
                    <input className="form-control" id="HotelID" type="text" placeholder="Enter Hotel ID" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="RoomCode">Room Code</label>
                    <input className="form-control" id="RoomCode" type="text" placeholder="Enter Room Code" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="RoomName">Room Name</label>
                    <input className="form-control" id="RoomName" type="text" placeholder="Enter Room Name" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="RateCode">Rate Code</label>
                    <input className="form-control" id="RateCode" type="text" placeholder="Enter Rate Code" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="RateName">Rate Name</label>
                    <input className="form-control" id="RateName" type="text" placeholder="Enter Rate Name" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="Derived">Derived</label>
                    <input className="form-control" id="Derived" type="text" placeholder="Enter Derived Value" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group">
                    <label htmlFor="RoomMaxOcupancy">Room Max Occupancy</label>
                    <input className="form-control" id="RoomMaxOcupancy" type="number" placeholder="Enter Room Max Occupancy" onInput={ this.onDataUpdate } />
                </div>
                <div className="form-group row">
                    <label className="col-md-5 col-form-label">Is Corporate Rate</label>
                    <div className="col-md-7 col-form-label">
                        <div className="form-check form-check-inline mr-1">
                            <input className="form-check-input" id="IsCorporateRate" type="checkbox" value="true" onInput={ this.onDataUpdate } />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PMSHotelProductForm;
