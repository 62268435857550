import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './table-diff.css';

class TableDiff extends Component {
    static TYPE = {
        NEW: 1,
        REMOVED: -1,
        MODIFIED: 2,
        SAME: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            columns: props.columns,
            defaultColDef: {
                flex: 1,
                minWidth: 110,
            },
        };
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    render() {
        const rowClassRules = {
            'table-diff-new': 'data.tableDiffStatus == ' + TableDiff.TYPE.NEW,
            'table-diff-removed': 'data.tableDiffStatus == ' + TableDiff.TYPE.REMOVED,
            'table-diff-modified': 'data.tableDiffStatus == ' + TableDiff.TYPE.MODIFIED,
            'table-diff-same': 'data.tableDiffStatus == ' + TableDiff.TYPE.SAME,
        };

        return (
            <div id="table" className="ag-theme-alpine table-theme">
                <AgGridReact
                    rowData={ this.props.data }
                    columnDefs={ this.state.columns }
                    defaultColDef={ this.state.defaultColDef }
                    onGridReady={ this.onGridReady }
                    pagination={ this.props.pagination !== null ? this.props.pagination : false }
                    enableCellTextSelection={ true }
                    domLayout={ 'autoHeight' }
                    rowClassRules={ rowClassRules }
                />
            </div>
        );
    }
}

export default TableDiff;
