import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './table-editor.css';
import ActionCellButtons from "./action-cell-buttons";

class TableReader extends Component {

    constructor(props) {
        super(props);

        this.onTableFilterTextBoxChanged = this.onTableFilterTextBoxChanged.bind(this);

        this.state = {
            columns: props.columns,
            defaultColDef: {
                flex: 1,
                minWidth: 110,
            },
        };

        this.props.onRef(this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onTableFilterTextBoxChanged() {
        this.props.onFilterUpdate(document.getElementById('table-filter-text-box').value);
    }

    render() {
        var tableTitle = this.props.tableTitle;

        return (
            <main className="c-main table-editor">
                <div className="container-fluid">
                    <div className="fade-in">
                        <div className="card">
                            <div className="card-header">{tableTitle} (Read only)</div>
                            <div className="card-body">
                                <div id="table" className="ag-theme-alpine table-theme">
                                    {!this.props.hideSearchFilter &&
                                    <div className="form-group row">
                                        <div className="col-sm-3">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Search"
                                                id="table-filter-text-box"
                                                onInput={this.onTableFilterTextBoxChanged}
                                            />
                                        </div>
                                    </div>
                                    }
                                    <AgGridReact
                                        rowData={ this.props.data }
                                        columnDefs={ this.state.columns }
                                        defaultColDef={ this.state.defaultColDef }
                                        onGridReady={ this.onGridReady }
                                        pagination={ this.props.pagination !== null ? this.props.pagination : false }
                                        frameworkComponents={{
                                            actionCellButtons: ActionCellButtons
                                        }}
                                        enableCellTextSelection={ true }
                                        domLayout={ 'autoHeight' }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default TableReader;
