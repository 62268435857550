import React from 'react';
import {
    CBreadcrumbRouter,
} from '@coreui/react';
import routes from '../routes';

const Breadcrumbs = () => {
    return (
        <CBreadcrumbRouter
            routes={ routes }
            className={ 'border-0 m-0 px-0 px-md-3' }
        />
    )
}

export default Breadcrumbs
