import React, {Component} from 'react'
import { CChartLine } from '@coreui/react-chartjs'

class ChartLines extends Component {
    constructor(props) {
        super(props);

        const default_options = {
            tooltips: {
                enabled: true,
                maintainAspectRatio: false,
            },
            elements: {
                line: {
                    borderWidth: 2
                },
                point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4
                }
            },
        };

        this.options = { ...default_options, ...props.options ? props.options : {} };
    }

    render() {
        return (
            <CChartLine
                datasets={ this.props.data }
                options={ this.options }
                labels={ this.props.labels }
            />
        );
    }
}

export default ChartLines
