import React, { Component } from 'react';

class Footer extends Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <footer className="c-footer">
                <div><a href="https://www.hotelrez.net">HotelREZ</a> © 2020</div>
            </footer>
        );
    }
}

export default Footer;
