import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './table-editor.css';
import BooleanCellEditor from './boolean-cell-editor';
import ActionCellButtons from './action-cell-buttons.js';

class TableEditor extends Component {

    constructor(props) {
        super(props);

        this.onTableFilterTextBoxChanged = this.onTableFilterTextBoxChanged.bind(this);

        this.state = {
            columns: props.columns,
            defaultColDef: {
                flex: 1,
                minWidth: 110,
            },
        };

        this.props.onRef(this);
    }

    scrollToRow = ( num, position ) => {
        this.gridApi.ensureIndexVisible( num ? num : 0, position ? position : 'bottom' );
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onCellUpdate = params => {
        this.props.onUpdate(params.data, function() {
            // Pass
        });
    };

    onShowAddNewRecordModal = params => {
        this.props.showAddNewRecordModal();
    };

    onTableFilterTextBoxChanged() {
        this.gridApi.setQuickFilter(document.getElementById('table-filter-text-box').value);
    }

    render() {
        var tableTitle = this.props.tableTitle;

        return (
            <main className="c-main table-editor">
                <div className="container-fluid">
                    <div className="fade-in">
                        <div className="card">
                            <div className="card-header">{ tableTitle } (Editable)</div>
                            <div className="card-body">
                                <div id="table" className="ag-theme-alpine table-theme">
                                    <div className="form-group row">
                                        <div className="col-sm-3">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Search"
                                                id="table-filter-text-box"
                                                onInput={ this.onTableFilterTextBoxChanged }
                                            />
                                        </div>
                                        <div className="col-sm-8">&nbsp;</div>
                                        <div className="col-sm-1 align-right">
                                            <button className="btn btn-secondary" type="button" onClick={ this.onShowAddNewRecordModal }>
                                                <svg className="c-icon c-icon-lg">
                                                    <use xlinkHref="/vendors/@coreui/icons/svg/free.svg#cil-plus"></use>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <AgGridReact
                                        rowData={ this.props.data }
                                        columnDefs={ this.state.columns }
                                        defaultColDef={ this.state.defaultColDef }
                                        onGridReady={ this.onGridReady }
                                        components={{
                                            booleanCellEditor: BooleanCellEditor,
                                        }}
                                        frameworkComponents={{
                                            actionCellButtons: ActionCellButtons
                                        }}
                                        onCellValueChanged={ this.onCellUpdate }
                                        pagination={ this.props.pagination !== null ? this.props.pagination : false }
                                        enableCellTextSelection={ true }
                                        domLayout={ 'autoHeight' }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default TableEditor;
