import React, { Component } from 'react';
import ReadonlySearchDataTable from '../table-templates/readonly-search-data-table';
import Services from '../helpers/services';
import Config from '../Config';
import ChartLines from '../components/charts/chart-lines';
import LeftSidebar from '../components/left-sidebar';
import RightSidebar from '../components/right-sidebar';
import Header from '../components/header';
import Footer from '../components/footer';
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCardHeader,
    CRow,
    CCol,
    CFormGroup,
    CLabel,
    CInput,
} from '@coreui/react';
import Loader from '../components/loader';
import moment from 'moment/moment';

class PMSPerformance extends Component {

    constructor(props) {
        super(props);

        var controller = this;

        this.getPMSPerformanceLogs = this.getPMSPerformanceLogs.bind(this);
        this.updateDataBasedOnFilter = this.updateDataBasedOnFilter.bind(this);

        controller.columns = [
            {
                headerName: 'ID',
                field: 'id',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 400,
            },
            {
                headerName: 'Date processed',
                field: 'date',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 400,
            },
            {
                headerName: 'Date triggered',
                field: 'dateTriggered',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Hotel Code',
                field: 'hotelCode',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Action',
                field: 'action',
                editable: false,
                sortable: false,
                filter: true,
                resizable: true,
                minWidth: 250,
            },
            {
                headerName: 'Username',
                field: 'customer',
                editable: false,
                sortable: false,
                filter: true,
                resizable: true,
                minWidth: 250,
            },
            {
                headerName: 'Duration in milliseconds',
                field: 'duration',
                editable: false,
                sortable: false,
                filter: true,
                resizable: true,
                minWidth: 300,
            },
        ];

        controller.titleSingle = 'PMS Performance Log';
        controller.titlePlural = 'PMS Performance Logs';
        controller.minSearchChars = 3;

        this.state = {
            loading: false,
            data: [],
            chartData: [],
            chartLabels: [],
            readCodeModal: {
                visible: false,
            },
        };
    }

    getPMSPerformanceLogs(filter, callback, errorCallback) {
        Services.getApiData(Config.pmsPerformanceApiPath(filter), callback, errorCallback);
    }

    getDateFromDateTime(datetime) {
        let date = moment(datetime);
        return date.format('DD/MM/YYYY');
    }

    updateDataBasedOnFilter(e) {
        const controller = this;

        const filter = e.target.value;

        if (controller.onFilterUpdateTimeout) {
            clearTimeout(controller.onFilterUpdateTimeout);
            controller.onFilterUpdateTimeout = null;
        }

        controller.onFilterUpdateTimeout = setTimeout(function () {
            if (filter && filter.length >= controller.minSearchChars) {
                controller.setState({
                    loading: true,
                });

                controller.getPMSPerformanceLogs(filter, function(data) {
                    let simpleChartData = [{
                        label: 'Response delay in seconds',
                        backgroundColor: 'rgb(228,102,81,0.9)',
                        data: [],
                        fill: true,
                    }];
                    let chartLabels = [];

                    let advancedChartData = [
                        {
                            label: 'OTA_HotelAvailNotifRQ delay in seconds',
                            backgroundColor: '#FF6384',
                            borderColor: '#FF6384',
                            data: [],
                            fill: false,
                        },
                        {
                            label: 'OTA_HotelRateAmountNotifRQ delay in seconds',
                            backgroundColor: '#36A2EB',
                            borderColor: '#36A2EB',
                            data: [],
                            fill: false,
                        },
                        {
                            label: 'OTA_NotifReportRQ delay in seconds',
                            backgroundColor: '#FFCE56',
                            borderColor: '#FFCE56',
                            data: [],
                            fill: false,
                        },
                        {
                            label: 'Product delay in seconds',
                            backgroundColor: '#4BC0C0',
                            borderColor: '#4BC0C0',
                            data: [],
                            fill: false,
                        },
                    ];

                    let dt = '';
                    let num = 0;
                    let sum = 0;
                    let advancedFactors = {
                        OTA_HotelAvailNotifRQ: {
                            num: 0,
                            sum: 0,
                        },
                        OTA_HotelRateAmountNotifRQ: {
                            num: 0,
                            sum: 0,
                        },
                        OTA_NotifReportRQ: {
                            num: 0,
                            sum: 0,
                        },
                        Product: {
                            num: 0,
                            sum: 0,
                        },
                    };

                    const reversedData = [...data].reverse();

                    for (const d of reversedData) {
                        let dateTriggered = controller.getDateFromDateTime(d.dateTriggered);

                        if (dt === '') {
                            dt = dateTriggered;
                        }

                        if (dateTriggered !== dt) {
                            chartLabels.push(dt);
                            dt = dateTriggered;
                            simpleChartData[0].data.push(Math.round((sum / num) / 1000));
                            advancedChartData[0].data.push(Math.round((advancedFactors.OTA_HotelAvailNotifRQ.sum / advancedFactors.OTA_HotelAvailNotifRQ.num) / 1000));
                            advancedChartData[1].data.push(Math.round((advancedFactors.OTA_HotelRateAmountNotifRQ.sum / advancedFactors.OTA_HotelRateAmountNotifRQ.num) / 1000));
                            advancedChartData[2].data.push(Math.round((advancedFactors.OTA_NotifReportRQ.sum / advancedFactors.OTA_NotifReportRQ.num) / 1000));
                            advancedChartData[3].data.push(Math.round((advancedFactors.Product.sum / advancedFactors.Product.num) / 1000));
                            sum = 0;
                            num = 0;
                        }

                        num++;
                        sum += d.duration;
                        advancedFactors[d.action].num++;
                        advancedFactors[d.action].sum += d.duration;
                    }

                    controller.setState({
                        simpleChartData: simpleChartData,
                        advancedChartData: advancedChartData,
                        chartLabels: chartLabels,
                        data: data,
                        loading: false,
                    }, function() {

                    });
                }, function(error) {
                    setTimeout(() => controller.setState({ loading: false }), 10);
                    console.log(error);
                });
            } else if (controller.state.data && controller.state.data.length > 0) {
                controller.setState({
                    data: [],
                    simpleChartData: [],
                    advancedChartData: [],
                    chartLabels: [],
                    loading: false,
                });
            }
        }, 700);
    }

    render() {

        return (
            <div>
                <LeftSidebar />
                <RightSidebar />
                <Loader loading={ this.state.loading } />
                <div className="c-wrapper">
                    <Header />
                    <div className="c-body c-main">
                        <div className="container-fluid">
                            <CRow>
                                <CCol xs="12" sm="12" md="12">
                                    <CCard>
                                        <CCardHeader>
                                            Search in Logs
                                        </CCardHeader>
                                        <CCardBody>
                                            <CRow>
                                                <CCol xs="12">
                                                    <CFormGroup>
                                                        <CLabel htmlFor="name">Hotel Code or PMS</CLabel>
                                                        <CInput
                                                            id="filter"
                                                            placeholder="Search by either Hotel Code or PMS"
                                                            required={ true }
                                                            onInput={ this.updateDataBasedOnFilter }
                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                            {this.state.chartLabels.length > 0 &&
                            <CRow>
                                <CCol sm="12" md="6">
                                    <CCard>
                                        <CCardHeader>
                                            PMS Performance
                                        </CCardHeader>
                                        <CCardBody>
                                            <ChartLines
                                                data={ this.state.simpleChartData }
                                                labels={ this.state.chartLabels }
                                            />
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="12" md="6">
                                    <CCard>
                                        <CCardHeader>
                                            PMS Action Performance
                                        </CCardHeader>
                                        <CCardBody>
                                            <ChartLines
                                                data={ this.state.advancedChartData }
                                                labels={ this.state.chartLabels }
                                            />
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                            }
                        </div>
                        {this.state.data.length > 0 &&
                        <ReadonlySearchDataTable
                            columns={this.columns}
                            titleSingle={this.titleSingle}
                            titlePlural={this.titlePlural}
                            data={this.state.data}
                            pagination={true}
                            minSearchChars={this.minSearchChars}
                            onRef={(ref) => (this.manageSearchDataTableRef = ref)}
                            hideSearchFilter={true}
                        />
                        }
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default PMSPerformance;
