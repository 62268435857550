import React, { Component } from 'react';

class ActionCellButtons extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.sendFullData ? this.props.data : this.props.value);
    }
    render() {
        if (this.props.value) {
            return (
                <a onClick={this.btnClickedHandler}>
                    <svg className="c-icon c-icon-lg">
                        <use xlinkHref={'/vendors/@coreui/icons/svg/free.svg#' + this.props.icon}></use>
                    </svg>
                </a>
            );
        } else {
            return (
                <div>Null</div>
            );
        }
    }
}

export default ActionCellButtons
