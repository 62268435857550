function BooleanCellEditor() {
    this.defaultImgStyle = 'padding-left:10px; padding-right:10px;  border: 1px solid transparent; padding: 4px;';
    this.selectedImgStyle = 'padding-left:10px; padding-right:10px; border: 1px solid lightgreen; padding: 4px;';
}

BooleanCellEditor.prototype.toggleValue = function() {
    this.selectValue(this.value === true ? 'true' : 'false');
};

BooleanCellEditor.prototype.init = function(params) {
    this.container = document.createElement('div');
    this.container.style = 'border: 1px solid grey;background: #e6e6e6;padding: 10px; text-align:center;display:inline-block;outline:none;top:25px;position:absolute;';
    this.container.tabIndex = '0';

    this.trueSpan = document.createElement('span');
    this.trueSpan.appendChild(document.createTextNode('true'));
    this.trueSpan.style = 'float: none;display: block;margin-bottom: 10px;';

    this.falseSpan = document.createElement('span');
    this.falseSpan.appendChild(document.createTextNode('false'));

    this.container.appendChild(this.trueSpan);
    this.container.appendChild(this.falseSpan);

    var that = this;
    this.trueSpan.addEventListener('click', function() {
        that.selectValue(true);
        params.stopEditing();
    });
    this.falseSpan.addEventListener('click', function() {
        that.selectValue(false);
        params.stopEditing();
    });

    this.selectValue(params.value);
};

BooleanCellEditor.prototype.selectValue = function(value) {
    this.value = value;
};

// gets called once when grid ready to insert the element
BooleanCellEditor.prototype.getGui = function() {
    return this.container;
};

BooleanCellEditor.prototype.afterGuiAttached = function() {
    this.container.focus();
};

BooleanCellEditor.prototype.getValue = function() {
    return this.value;
};

BooleanCellEditor.prototype.destroy = function() {
};

BooleanCellEditor.prototype.isPopup = function() {
    return true;
};

export default BooleanCellEditor;
