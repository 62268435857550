import React, { Component } from 'react';
import LeftSidebar from '../components/left-sidebar';
import RightSidebar from '../components/right-sidebar';
import Header from '../components/header';
import Footer from '../components/footer';
import FileUploadDropZone from '../components/dropzones/file-upload-drop-zone';
import './pms-hotel-product-upload.css';
import { withSnackbar } from 'notistack';
import Services from '../helpers/services';
import Config from '../Config';
import TableDiff from '../components/data-tables/table-diff';

import low from 'lowdb';
import LocalStorage from 'lowdb/adapters/LocalStorage';

class PMSHotelProductUpload extends Component {

    constructor(props) {
        super(props);

        var controller = this;

        controller.title = 'PMS Hotel Product Excel Files Uploader';
        controller.mimeTypeAccept = {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            label: 'Excel files',
        };
        controller.maxUploadFiles = 2;
        controller.fileSizeInMetric = 'kb';
        controller.labels = {
            onDrop: 'Drop files here',
            instructions: 'Drag n Drop the 2 excel files here, or click to select them',
            accepts: 'Accepts',
            acceptedFiles: 'Chosen files',
        };

        this.state = {
            uploadButtonLoading: false,
            saveButtonLoading: false,
            uploadedFiles: null,
            data: null,
            originalData: null,
        };

        controller.columns = [
            {
                headerName: 'HotelID',
                field: 'HotelID',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Room Code',
                field: 'RoomCode',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Room Name',
                field: 'RoomName',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 300,
            },
            {
                headerName: 'Rate Code',
                field: 'RateCode',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Rate Name',
                field: 'RateName',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 400,
            },
            {
                headerName: 'Derived',
                field: 'Derived',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Room Max Ocupancy',
                field: 'RoomMaxOcupancy',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 200,
            },
            {
                headerName: 'Is Corporate Rate',
                field: 'IsCorporateRate',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 200,
            },
        ];

        controller.titleSingle = 'Please review data before you permanently insert in REZswitch';
        controller.titlePlural = 'Please review data before you permanently insert in REZswitch';

        this.filesDropped = this.filesDropped.bind( this );
        this.uploadButtonClicked = this.uploadButtonClicked.bind( this );
        this.isReadyForUpload = this.isReadyForUpload.bind( this );
        this.uploadFiles = this.uploadFiles.bind( this );
        this.saveButtonClicked = this.saveButtonClicked.bind( this );
    }

    filesDropped = ( files ) => {
        console.log(files);
        var controller = this;

        controller.setState({
            uploadedFiles: files,
        });
    }

    uploadButtonClicked = () => {
        var controller = this;

        if (this.isReadyForUpload()) {
            controller.setState({
                uploadButtonLoading: true,
            });
            this.uploadFiles(
                this.state.uploadedFiles,
                ( response ) => {
                    controller.setState({
                        originalData: JSON.parse(JSON.stringify(response.data)),
                    });
                    const uniqueHotelIds = [];
                    response.data.map(( hotel ) => {
                        if (uniqueHotelIds.indexOf(hotel.HotelID) === -1) {
                            uniqueHotelIds.push(hotel.HotelID);
                        }
                    });
                    controller.getCurrentHotelProducts(uniqueHotelIds,
                        ( responseCurrentdata ) => {
                            const adapter = new LocalStorage('hrezadminportaldb');
                            const db = low(adapter);

                            // Empty db
                            db.get('hotelProducts').remove().write();

                            // Start writing on db
                            db.defaults({ hotelProducts: [] }).write();

                            // Define index
                            var hotelProductsIndex = db.get('hotelProducts');

                            for (var currentHotelProduct of responseCurrentdata) {
                                hotelProductsIndex.push(currentHotelProduct).write();
                            }

                            var data = response.data.map(( item ) => {
                                // Check item in current db
                                var currentHotelProduct = hotelProductsIndex
                                    .find({ HotelID: item.HotelID, RoomCode: item.RoomCode, RateCode: item.RateCode })
                                    .value();

                                if ( currentHotelProduct ) {
                                    if (
                                        currentHotelProduct.HotelID === item.HotelID &&
                                        currentHotelProduct.RoomCode === item.RoomCode &&
                                        currentHotelProduct.RoomName === item.RoomName &&
                                        currentHotelProduct.RateCode === item.RateCode &&
                                        currentHotelProduct.RateName === item.RateName &&
                                        currentHotelProduct.Derived === item.Derived &&
                                        currentHotelProduct.RoomMaxOcupancy === item.RoomMaxOcupancy &&
                                        currentHotelProduct.IsCorporateRate === item.IsCorporateRate
                                    ) {
                                        item.tableDiffStatus = TableDiff.TYPE.SAME;
                                    } else {
                                        item.RoomName = currentHotelProduct.RoomName === item.RoomName ?
                                            item.RoomName :
                                            currentHotelProduct.RoomName + ' -> ' + item.RoomName;
                                        item.RateName = currentHotelProduct.RateName === item.RateName ?
                                            item.RateName :
                                            currentHotelProduct.RateName + ' -> ' + item.RateName;
                                        item.Derived = currentHotelProduct.Derived === item.Derived ?
                                            item.Derived :
                                            currentHotelProduct.Derived + ' -> ' + item.Derived;
                                        item.RoomMaxOcupancy = currentHotelProduct.RoomMaxOcupancy === item.RoomMaxOcupancy ?
                                            item.RoomMaxOcupancy :
                                            currentHotelProduct.RoomMaxOcupancy + ' -> ' + item.RoomMaxOcupancy;
                                        item.IsCorporateRate = currentHotelProduct.IsCorporateRate === item.IsCorporateRate ?
                                            item.IsCorporateRate :
                                            currentHotelProduct.IsCorporateRate + ' -> ' + item.IsCorporateRate;
                                        item.tableDiffStatus = TableDiff.TYPE.MODIFIED;
                                    }

                                    // Remove item from local db
                                    hotelProductsIndex
                                        .remove({ HotelID: item.HotelID, RoomCode: item.RoomCode, RateCode: item.RateCode })
                                        .write();
                                } else {
                                    item.tableDiffStatus = TableDiff.TYPE.NEW;
                                }

                                return item;
                            });

                            // Include removed items in table
                            for (var oldItem of hotelProductsIndex.value()) {
                                oldItem.tableDiffStatus = TableDiff.TYPE.REMOVED;
                                data.push(oldItem);
                            }

                            // Clear local database
                            hotelProductsIndex.remove().write();

                            controller.setState({
                                uploadButtonLoading: false,
                                data: data,
                            });
                        },
                        ( error ) => {
                            controller.setState({
                                uploadButtonLoading: false,
                            });
                            controller.props.enqueueSnackbar('Error: ' + error, {
                                variant: 'error',
                            });
                        }
                    );
                },
                ( error ) => {
                    controller.setState({
                        uploadButtonLoading: false,
                    });
                    controller.props.enqueueSnackbar('Error: ' + error, {
                        variant: 'error',
                    });
                }
            );
        } else {
            controller.props.enqueueSnackbar('Error: Please first choose proper files to upload.', {
                variant: 'error',
            });
        }
    }

    isReadyForUpload = () => {
        if (this.state.uploadedFiles && this.state.uploadedFiles.length === 2) {
            return true;
        } else {
            return false;
        }
    }

    uploadFiles(files, callback, errorCallback) {
        Services.uploadFilesApiData(Config.hotelProductsUploadFilesApiPath, files, callback, errorCallback);
    }

    getCurrentHotelProducts(hotelIds, callback, errorCallback) {
        const criteria = {
            HotelIDs: hotelIds,
        };
        Services.getWithPostApiData(Config.hotelProductsApiPath(), criteria, callback, errorCallback);
    }

    saveButtonClicked = () => {
        let controller = this;

        if ( this.state.originalData ) {
            controller.setState({
                saveButtonLoading: true,
            });
            Services.addWithPutApiData(
                Config.hotelProductsApiPath(),
                this.state.originalData,
                ( data ) => {
                    controller.setState({
                        saveButtonLoading: false,
                        data: null,
                        originalData: null,
                        uploadedFiles: null,
                        uploadButtonLoading: null,
                    });
                    controller.props.enqueueSnackbar('Success', {
                        variant: 'success',
                    });
                },
                ( error ) => {
                    controller.props.enqueueSnackbar('Error: ' + error, {
                        variant: 'error',
                    });
                }
            );
        } else {
            let error = 'No data found to save. Please contact your technical support';
            controller.props.enqueueSnackbar('Error: ' + error, {
                variant: 'error',
            });
        }
    }

    render() {
        return (
            <div>
                <LeftSidebar />
                <RightSidebar />
                <div className="c-wrapper">
                    <Header />
                    <div className="c-body">
                        <main className="c-main table-editor">
                            <div className="container-fluid">
                                <div className="fade-in">
                                    <div className="card">
                                        <div className="card-header">{ this.title }</div>
                                        <div className="card-body">
                                            { this.state.data === null &&
                                                <FileUploadDropZone
                                                    accepts={ this.mimeTypeAccept }
                                                    onDrop={ this.filesDropped }
                                                    maxFiles={ this.maxUploadFiles }
                                                    labels={ this.labels }
                                                    fileSizeMetric={ this.fileSizeInMetric }
                                                />
                                            }
                                            { this.isReadyForUpload() && this.state.data === null &&
                                            <div className="row">
                                                <div className="col-md-12 py-4 text-right">
                                                    <button
                                                        className="btn btn-primary c-loading-button"
                                                        onClick={ this.uploadButtonClicked }>
                                                        <span
                                                            className={
                                                                this.state.uploadButtonLoading ?
                                                                    'c-loading-button-spinner spinner-border spinner-border-sm' :
                                                                    ''
                                                            }>
                                                        </span>
                                                        Upload files
                                                    </button>
                                                </div>
                                            </div>
                                            }
                                            { this.state.data !== null &&
                                                <TableDiff
                                                    columns={ this.columns }
                                                    titleSingle={ this.titleSingle }
                                                    titlePlural={ this.titlePlural }
                                                    data={ this.state.data }
                                                    pagination={ true }
                                                    minSearchChars={ this.minSearchChars }
                                                    hideSearchFilter={ true }
                                                    onRef={ (ref) => ( this.manageSearchDataTableRef = ref ) }
                                                />
                                            }
                                            { this.state.data !== null &&
                                                <div className="row">
                                                    <div className="col-md-12 py-4 text-right">
                                                        <button
                                                            className="btn btn-primary c-loading-button"
                                                            onClick={ this.saveButtonClicked }>
                                                            <span
                                                                className={
                                                                    this.state.saveButtonLoading ?
                                                                        'c-loading-button-spinner spinner-border spinner-border-sm' :
                                                                        ''
                                                                }>
                                                            </span>
                                                            Permanently store changes
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(PMSHotelProductUpload);
