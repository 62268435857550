import React, { Component } from 'react';
import ManageSearchDataTable from '../table-templates/manage-search-data-table';
import Services from '../helpers/services';
import Config from '../Config';
import LeftSidebar from '../components/left-sidebar';
import RightSidebar from '../components/right-sidebar';
import Header from '../components/header';
import Footer from '../components/footer';
import PMSHotelProductForm from '../components/forms/pms-hotel-product-form';

class PMSHotelProductManage extends Component {

    constructor(props) {
        super(props);

        var controller = this;

        controller.columns = [
            {
                headerName: 'ID',
                field: 'ID',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
                minWidth: 90,
            },
            {
                headerName: 'Hotel ID',
                field: 'HotelID',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Room Code',
                field: 'RoomCode',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Room Name',
                field: 'RoomName',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Rate Code',
                field: 'RateCode',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Rate Name',
                field: 'RateName',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
                minWidth: 350,
            },
            {
                headerName: 'Derived',
                field: 'Derived',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
                minWidth: 170,
            },
            {
                headerName: 'Room Max Occupancy',
                field: 'RoomMaxOcupancy',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
                minWidth: 170,
            },
            {
                headerName: 'Is Corporate Rate',
                field: 'IsCorporateRate',
                editable: true,
                sortable: true,
                filter: true,
                resizable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['true', 'false'],
                },
                minWidth: 200,
            },
            {
                headerName: 'Manage',
                field: 'ID',
                cellRenderer: 'actionCellButtons',
                cellRendererParams: {
                    clicked: function(data) {
                        controller.manageSearchDataTableRef.showProceedModal(data);
                    },
                    icon: 'cil-trash',
                    sendFullData: true,
                },
                editable: false,
                sortable: false,
                filter: false,
                resizable: true,
                maxWidth: 150,
            },
        ];

        controller.titleSingle = 'PMS Hotel Product';
        controller.titlePlural = 'PMS Hotel Products';
    }

    getHotelProducts(callback, errorCallback) {
        const criteria = {
            HotelIDs: [],
        };
        Services.getWithPostApiData(Config.hotelProductsApiPath(), criteria, callback, errorCallback);
    }

    addHotelProduct(hotel, callback, errorCallback) {
        Services.addWithPutApiData(Config.hotelUniqueAddProductsApiPath(), [hotel], callback, errorCallback);
    }

    updateHotelProduct(hotel, callback, errorCallback) {
        Services.updateApiData(Config.hotelProductsApiPath, hotel, callback, errorCallback);
    }

    deleteHotelProduct(hotelId, callback, errorCallback) {
        Services.deleteApiData(Config.hotelProductsApiPath, hotelId, callback, errorCallback);
    }

    render() {
        return (
            <div>
                <LeftSidebar />
                <RightSidebar />
                <div className="c-wrapper">
                    <Header />
                    <div className="c-body">
                        <ManageSearchDataTable
                            columns={ this.columns }
                            titleSingle={ this.titleSingle }
                            titlePlural={ this.titlePlural }
                            getRows={ this.getHotelProducts }
                            addRow={ this.addHotelProduct }
                            updateRow={ this.updateHotelProduct }
                            deleteRow={ this.deleteHotelProduct }
                            itemUniqueColumn={ 'ID' }
                            itemHumanFriendlyColumn={ 'RateCode' }
                            addRowForm={ PMSHotelProductForm }
                            pagination={ true }
                            onRef={ (ref) => ( this.manageSearchDataTableRef = ref ) }
                        />
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default PMSHotelProductManage;
