import React, { Component } from 'react';
import TableEditor from '../components/data-tables/table-editor';
import Loader from '../components/loader';
import { withSnackbar } from 'notistack';
import ProceedCancelModal from '../components/modals/proceed-cancel-modal';
import DataEditorModal from '../components/modals/data-editor-modal';

class ManageSearchDataTable extends Component {

    constructor(props) {
        super(props);

        var controller = this;

        this.addRow = this.addRow.bind(this);
        this.updateRow = this.updateRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);

        this.showProceedModal = this.showProceedModal.bind(this);
        this.showDataEditorModal = this.showDataEditorModal.bind(this);
        this.cancelAnyModal = this.cancelAnyModal.bind(this);

        this.proceedModalDeleteRow = this.proceedModalDeleteRow.bind(this);
        this.dataEditorModalSaveRow = this.dataEditorModalSaveRow.bind(this);

        this.onFormDataUpdate = this.onFormDataUpdate.bind(this);

        controller.columns = this.props.columns;
        controller.titleSingle = this.props.titleSingle;
        controller.titlePlural = this.props.titlePlural;

        controller.state = {
            loading: true,
            proceedModal: {
                visible: false,
            },
            dataEditorModal: {
                visible: false,
            },
        };

        controller.services = {
            getRows: this.props.getRows,
            addRow: this.props.addRow,
            updateRow: this.props.updateRow,
            deleteRow: this.props.deleteRow,
        };

        this.getRows(this);

        this.props.onRef(this);
    }

    getRows(controller, callback) {
        controller.services.getRows(function(data) {
            controller.setState({
                data: data,
                loading: false,
            }, function() {
                if (callback) {
                    callback();
                }
            });

        }, function(error) {
            setTimeout(() => controller.setState({ loading: false }), 10);
            console.log(error);
        });
    }

    addRow(item, callback) {
        var controller = this;

        controller.services.addRow(item, function(data){
            // Get newly inserted id from DB
            callback(data);

            controller.props.enqueueSnackbar('Success', {
                variant: 'success',
            });
        }, function(error){
            controller.props.enqueueSnackbar('Error: ' + error, {
                variant: 'error',
            });
        });
    }

    updateRow(item, callback) {
        var controller = this;

        controller.services.updateRow(item, function(data){
            callback(item);

            controller.props.enqueueSnackbar('Success', {
                variant: 'success',
            });
        }, function(error){
            controller.props.enqueueSnackbar('Error: ' + error, {
                variant: 'error',
            });
        });
    }

    deleteRow(itemId, callback) {
        var controller = this;

        controller.services.deleteRow(itemId, function(data){
            callback();

            controller.props.enqueueSnackbar('Success', {
                variant: 'success',
            });
        }, function(error){
            controller.props.enqueueSnackbar('Error: ' + error, {
                variant: 'error',
            });
        });
    }

    cancelAnyModal() {
        var controller = this;

        controller.setState({
            proceedModal: {
                visible: false,
            },
            dataEditorModal: {
                visible: false,
            },
        });
    }

    proceedModalDeleteRow(data) {
        var controller = this;
        let id = data[controller.props.itemUniqueColumn];

        controller.deleteRow(id, function() {
            controller.getRows(controller);
            controller.cancelAnyModal();
        });
    }

    showProceedModal(data) {
        var controller = this;

        controller.setState({
            proceedModal: {
                visible: true,
                title: controller.titleSingle + ' deletion warning!',
                text: 'Please confirm you want to delete ' + controller.titleSingle + ' with ID: ' + data[controller.props.itemUniqueColumn] + ' identified as ' + data[controller.props.itemHumanFriendlyColumn],
                type: 'danger',
                proceed: this.proceedModalDeleteRow.bind(this, data),
            }
        });
    }

    dataEditorModalSaveRow() {
        var controller = this;
        var item = this.state.dataEditorModal.data;
        var isComplete = this.state.dataEditorModal.isComplete;

        if (isComplete) {
            controller.addRow(item, function() {
                controller.getRows(controller, function() {
                    controller.cancelAnyModal();
                    controller.tableEditorRef.scrollToRow(controller.state.data.length - 1);
                });
            });
        } else {
            controller.props.enqueueSnackbar('All fields are required', {
                variant: 'error',
            });
        }
    }

    onFormDataUpdate(item, isComplete) {
        var dataEditorModal = this.state.dataEditorModal;

        dataEditorModal.data = item;
        dataEditorModal.isComplete = isComplete;

        this.setState({
            dataEditorModal: dataEditorModal,
        });
    }

    showDataEditorModal( data ) {
        var controller = this;

        controller.setState({
            dataEditorModal: {
                visible: true,
                title: 'Create new ' + controller.titleSingle,
                html: <this.props.addRowForm onDataUpdate={ this.onFormDataUpdate } />,
                type: 'info',
                save: this.dataEditorModalSaveRow.bind(this, data),
                data: {},
            }
        });
    }

    render() {
        return (
            <div className="c-body">
                <Loader loading={ this.state.loading } />
                {this.state.data &&
                <TableEditor
                    tableTitle={ this.titlePlural } columns={ this.columns }
                    data={ this.state.data }
                    onUpdate={ this.updateRow }
                    showAddNewRecordModal={ this.showDataEditorModal }
                    onRef={ (ref) => ( this.tableEditorRef = ref ) }
                    pagination={ this.props.pagination }
                />
                }

                <ProceedCancelModal
                    title={ this.state.proceedModal.title }
                    text={ this.state.proceedModal.text }
                    type={ this.state.proceedModal.type }
                    visible={ this.state.proceedModal.visible }
                    onCancel={ this.cancelAnyModal }
                    onProceed={ this.state.proceedModal.proceed } />
                <DataEditorModal
                    title={ this.state.dataEditorModal.title }
                    html={ this.state.dataEditorModal.html }
                    type={ this.state.dataEditorModal.type }
                    visible={ this.state.dataEditorModal.visible }
                    onCancel={ this.cancelAnyModal }
                    onSave={ this.state.dataEditorModal.save } />
            </div>
        );
    }
}

export default withSnackbar(ManageSearchDataTable);
