import React, { Component } from 'react';
import LeftSidebar from '../components/left-sidebar';
import RightSidebar from '../components/right-sidebar';
import Header from '../components/header';
import Footer from '../components/footer';
import TableReader from '../components/data-tables/table-reader';
import Loader from '../components/loader';
import { withSnackbar } from 'notistack';

class ReadonlySearchDataTable extends Component {

    constructor(props) {
        super(props);

        var controller = this;

        this.onFilterUpdate = this.onFilterUpdate.bind(this);

        controller.columns = this.props.columns;
        controller.titleSingle = this.props.titleSingle;
        controller.titlePlural = this.props.titlePlural;

        controller.state = {
            loading: this.props.data ? false : true,
            proceedModal: {
                visible: false,
            },
            dataEditorModal: {
                visible: false,
            },
        };

        controller.services = {
            getRows: this.props.getRows,
        };

        if (!this.props.data) {
            this.getRows('', this);
        }

        this.props.onRef(this);

        controller.onFilterUpdateTimeout = null;
    }

    getRows(filter, controller, callback) {
        if (filter && filter.length >= this.props.minSearchChars) {
            controller.setState({
                loading: true,
            });
        }

        controller.services.getRows(filter, function(data) {
            controller.setState({
                data: data,
                loading: false,
            }, function() {
                if (callback) {
                    callback();
                }
            });

        }, function(error) {
            setTimeout(() => controller.setState({ loading: false }), 10);
            console.log(error);
        });
    }

    onFilterUpdate(filter) {
        const controller = this;

        if (controller.onFilterUpdateTimeout) {
            clearTimeout(controller.onFilterUpdateTimeout);
            controller.onFilterUpdateTimeout = null;
        }

        controller.onFilterUpdateTimeout = setTimeout(function () {
            controller.getRows(filter, controller);
        }, 700);
    }

    render() {
        return (
            <div className="c-body">
                <Loader loading={ this.state.loading } />
                {(this.props.data || this.state.data) &&
                <TableReader
                    tableTitle={ this.titlePlural } columns={ this.columns }
                    data={ this.props.data ? this.props.data : this.state.data }
                    onRef={ (ref) => ( this.tableEditorRef = ref ) }
                    pagination={ this.props.pagination }
                    onFilterUpdate={ this.onFilterUpdate }
                    hideSearchFilter={ this.props.hideSearchFilter }
                />
                }
            </div>
        );
    }
}

export default withSnackbar(ReadonlySearchDataTable);
