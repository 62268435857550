import React, { Component } from 'react';
import ReadonlySearchDataTable from '../table-templates/readonly-search-data-table';
import Services from '../helpers/services';
import Config from '../Config';
import ReadCodeModal from '../components/modals/read-code-modal';
import LeftSidebar from "../components/left-sidebar";
import RightSidebar from "../components/right-sidebar";
import Header from "../components/header";
import Footer from "../components/footer";

class PMSMessage extends Component {

    constructor(props) {
        super(props);

        var controller = this;

        this.getPMSMessages = this.getPMSMessages.bind(this);
        this.onReadCodeModalClose = this.onReadCodeModalClose.bind(this);
        this.onReadCodeModalOpen = this.onReadCodeModalOpen.bind(this);

        controller.columns = [
            {
                headerName: 'ID',
                field: 'id',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 400,
            },
            {
                headerName: 'Message ID',
                field: 'messageId',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 400,
            },
            {
                headerName: 'Hotel Code',
                field: 'hotelCode',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Status',
                field: 'status',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Original Request',
                field: 'originalRequest',
                editable: false,
                sortable: false,
                filter: false,
                resizable: true,
                minWidth: 150,
                cellRenderer: 'actionCellButtons',
                cellRendererParams: {
                    clicked: function(data) {
                        controller.onReadCodeModalOpen(data);
                    },
                    icon: 'cil-cloud-download',
                },
            },
            {
                headerName: 'New Request',
                field: 'newRequest',
                cellRenderer: 'actionCellButtons',
                cellRendererParams: {
                    clicked: function(data) {
                        controller.onReadCodeModalOpen(data);
                    },
                    icon: 'cil-cloud-download',
                },
                editable: false,
                sortable: false,
                filter: false,
                resizable: true,
                minWidth: 150,
            },
            {
                headerName: 'Original Response',
                field: 'originalResponse',
                cellRenderer: 'actionCellButtons',
                cellRendererParams: {
                    clicked: function(data) {
                        controller.onReadCodeModalOpen(data);
                    },
                    icon: 'cil-cloud-download',
                },
                editable: false,
                sortable: false,
                filter: false,
                resizable: true,
                minWidth: 150,
            },
            {
                headerName: 'New Response',
                field: 'newResponse',
                cellRenderer: 'actionCellButtons',
                cellRendererParams: {
                    clicked: function(data) {
                        controller.onReadCodeModalOpen(data);
                    },
                    icon: 'cil-cloud-download',
                },
                editable: false,
                sortable: false,
                filter: false,
                resizable: true,
                minWidth: 150,
            },
            {
                headerName: 'Type',
                field: 'type',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Type Name',
                field: 'typeName',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 170,
            },
            {
                headerName: 'Received Timestamp',
                field: 'timeStamp',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 220,
            },
            {
                headerName: 'Processed Timestamp',
                field: 'newTimeStamp',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 220,
            },
            {
                headerName: 'PMS',
                field: 'username',
                editable: false,
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 220,
            },
        ];

        controller.titleSingle = 'PMS Message';
        controller.titlePlural = 'PMS Messages';
        controller.minSearchChars = 3;

        this.state = {
            readCodeModal: {
                visible: false,
            },
        };
    }

    getPMSMessages(filter, callback, errorCallback) {
        Services.getApiData(Config.pmsMessageApiPath(filter, this.minSearchChars), callback, errorCallback);
    }

    onReadCodeModalClose() {
        this.setState({
            readCodeModal: {
                visible: false,
            },
        });
    }

    onReadCodeModalOpen(content) {
        this.setState({
            readCodeModal: {
                visible: true,
                title: 'XML Reader',
                content: content,
            },
        });
    }

    render() {

        return (
            <div>
                <LeftSidebar />
                <RightSidebar />
                <div className="c-wrapper">
                    <Header />
                    <div className="c-body">
                        <ReadonlySearchDataTable
                            columns={ this.columns }
                            titleSingle={ this.titleSingle }
                            titlePlural={ this.titlePlural }
                            getRows={ this.getPMSMessages }
                            pagination={ true }
                            minSearchChars={ this.minSearchChars }
                            onRef={ (ref) => ( this.manageSearchDataTableRef = ref ) }
                        />
                        <ReadCodeModal
                            content={ this.state.readCodeModal.content }
                            title={ this.state.readCodeModal.title }
                            type={ 'info' }
                            visible={ this.state.readCodeModal.visible }
                            onClose={ this.onReadCodeModalClose }
                        />
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default PMSMessage;
