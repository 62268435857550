import React, { Component } from 'react';

class Loader extends Component {
    constructor(props) {
        super(props);

        var loading = this.props.loading;

        this.state = {
            loading: loading,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading !== this.props.loading) {
            this.setState({
                loading: this.props.loading,
            });
        }
    }

    render() {
        return (
            <div id="loader" className={ this.state.loading ? 'loader-container' : 'loader-container loader-hidden' }>
                <div className="spinner spinner3"></div>
            </div>
        );
    }
}

export default Loader;
