class Config {

    static expiredJWTRedirectURL = 'https://portal.hotelrez.co.uk/';

    static APIs = {
        baseApiPath: process.env.REACT_APP_BASE_API_PATH,
        commissionHotelApi: '/commissionhotel',
        pmsMessageApi: '/pmsmessage',
        pmsReservationApi: '/pmsreservation',
        pmsHotelApi: '/pmshotel',
        pmsPerformanceApi: '/pmsperformance',
        pmsHotelProductsApi: '/hotelproduct',
        pmsHotelProductsApiAddUniqueRoomRate: '/adduniqueroomratecomb',
        pmsHotelProductUploadFilesApi: '/hotelproduct/uploadfiles',
    }

    static toastrOptions = {
        // 'closeButton': false,
        // 'debug': false,
        // 'newestOnTop': false,
        // 'progressBar': false,
        // 'positionClass': 'toast-top-right',
        // 'preventDuplicates': false,
        // 'onclick': null,
        // 'showDuration': '120',
        // 'hideDuration': '500',
        // 'timeOut': '2000',
        // 'extendedTimeOut': '1000',
        // 'showEasing': 'swing',
        // 'hideEasing': 'linear',
        // 'showMethod': 'fadeIn',
        // 'hideMethod': 'fadeOut',
    };

    static commissionHotelApiPath(id) {
        return Config.APIs.baseApiPath + Config.APIs.commissionHotelApi + (id ? '/' + id : '');
    }

    static pmsMessageApiPath(filter, num) {
        return Config.APIs.baseApiPath + Config.APIs.pmsMessageApi + '/' + (filter ? filter : '-') + '/' + num;
    }

    static pmsReservationsByHotelApiPath(hotelCode) {
        return Config.APIs.baseApiPath + Config.APIs.pmsReservationApi + '/byhotelcode/hotelcode=' + (hotelCode ? hotelCode : '-');
    }

    static pmsReservationsByMessageCodeApiPath(messageId) {
        return Config.APIs.baseApiPath + Config.APIs.pmsReservationApi + '/bymessageid/messageid=' + (messageId ? messageId : '-');
    }

    static pmsPerformanceApiPath(filter) {
        return Config.APIs.baseApiPath + Config.APIs.pmsPerformanceApi + '/' + (filter ? filter : '-');
    }

    static pmsHotelApiPath(id) {
        return Config.APIs.baseApiPath + Config.APIs.pmsHotelApi + (id ? '/' + id : '');
    }

    static getJWTToken() {
        return localStorage.getItem('admin_portal_jwt_token');
    }

    static hotelProductsApiPath(id) {
        return Config.APIs.baseApiPath + Config.APIs.pmsHotelProductsApi + (id ? '/' + id : '');
    }

    // new add product for unique combined room and ratte
    static hotelUniqueAddProductsApiPath() {
        return Config.APIs.baseApiPath + Config.APIs.pmsHotelProductsApiAddUniqueRoomRate;
    }

    static hotelProductsUploadFilesApiPath() {
        return Config.APIs.baseApiPath + Config.APIs.pmsHotelProductUploadFilesApi;
    }

}

export default Config;
